import React from "react"
import { Link } from "gatsby"
import 'animate.css/animate.css'

import TagList from "../components/tagList"

import logo from '../../content/assets/images/regga-signature.png'

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath

  return (
    <div className="dark">
      <header id="head-block">
        <div id="head">
          <div>
            <Link to={`/`} style={{textDecoration: `none`}}>
              <img width="80" height="28" className="animate__animated animate__fadeInDown animate__slow" style={{height:28,width:'auto',margin:'auto',display:'block',marginTop:'38px'}} alt="regga-logo" src={logo}/>
            </Link>
          </div>

        </div>
      </header>
      <div className="" data-is-root-path={isRootPath}>
        <main>{children}</main>
        <footer id="foot">
          <div
              style={{
                background: `#141414`
              }}
          >
            <table><tbody><tr>
              <td>
                <Link to={`/`} style={{textDecoration: `none`}}>
                  <img width="100" height="35" style={{height:35,width:'auto',margin:'auto',display:'block'}} alt="regga-logo" src={logo}/>
                </Link>
              </td>
            </tr></tbody></table>

            <TagList/>

            <a target="_blank" rel="noopener noreferrer" href="https://instagram.com/reggarantai" style={{color:"#fff",boxShadow:"none"}}>Instagram</a>
            <a target="_blank" rel="noopener noreferrer" href="https://facebook.com/reggarantai" style={{color:"#fff",boxShadow:"none"}}>Facebook</a>
            <a target="_blank" rel="noopener noreferrer" href="https://github.com/reggarantai" style={{color:"#fff",boxShadow:"none"}}>Github</a>
            <a target="_blank" rel="noopener noreferrer" href="skype:reggarantai?chat" style={{color:"#fff",boxShadow:"none"}}>Skype</a>
            <a target="_blank" rel="noopener noreferrer" href="https://reggarantai.com/" style={{color:"#fff",boxShadow:"none"}}>Works</a>
            <div className="line"></div>
            <small>
              © {new Date().getFullYear()} by <a target="_blank" rel="noopener noreferrer" href="https://reggarantai.com/" style={{color:"#eee",textDecoration:"none",margin:0}}>reggarantai</a>
              <br />All rights reserved
            </small>
          </div>
        </footer>
      </div>
    </div>
  )
}

export default Layout
