/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({ description, lang, meta, title, image, url , bodyAttributes}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            image
            social {
              twitter
            }
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const metaImage = image || site.siteMetadata.image
  const defaultTitle = site.siteMetadata?.title
  const pUrl = `https://regga.id`

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      bodyAttributes={bodyAttributes || null}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      link={[{
        rel: `canonical`, href: `${pUrl}${(url || '')}`
      }]}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `author`,
          content: `Regga @reggarantai`,
        },
        {
          itemprop: `name`,
          content: title,
        },
        {
          itemprop: `description`,
          content: metaDescription,
        },
        {
          itemprop: `image`,
          content: `${pUrl}${metaImage}`,
        },
        {
          property: `og:locale`,
          content: `id_ID`
        },
        {
          property: `og:site_name`,
          content: `Regga`
        },
        {
          property: `og:url`,
          content: `${pUrl}${(url || '')}`,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: `${pUrl}${metaImage}`,
        },
        {
          name: `twitter:site`,
          content: `@reggarantai`
        },
        {
          name: `twitter:site:id`,
          content: `176690755`
        },
        {
          name: `twitter:creator`,
          content: `@reggarantai`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: `${pUrl}${metaImage}`,
        },
        {
          name: `robots`,
          content: `index, follow`,
        },
        {
          name: `googlebot`,
          content: `index, follow`,
        },
        {
          name: `google-site-verification`,
          content: `peDmUoo03oUhv79Ernt4YV8bWfZO7FPhq8UEgF4-3eE`
        }
      ].concat(meta)}
    >
      <script>
        {`if ('serviceWorker' in navigator) {
          navigator.serviceWorker.getRegistrations().then(function(registrations) {
            for (let registration of registrations) {
              registration.unregister();
            }
          });
        }`}
      </script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `id-ID`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
