import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import _ from 'lodash'

const TagList = () => {
  const data = useStaticQuery(graphql`
    query tagsGroup {
      tagsGroup: allMdx(
        limit:3000
        filter: {frontmatter: {draft: {eq:false}}}
      ) {
        group(field: frontmatter___tags) {
          fieldValue
        }
      }
    }
  `)
  const tags = data.tagsGroup.group
  return (
    <div className="tag-list" style={{maxWidth: 580,margin: 'auto',marginBottom:20}}>
      <Link to={`/coding/`}>coding</Link>
      {
        tags.map((tag) => {
          return (
            <Link to={`/${_.kebabCase(tag.fieldValue)}/`} key={tag.fieldValue}>{tag.fieldValue}</Link>
          )
        })
      }
    </div>
  )
}

export default TagList
